//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base/layout';
@import 'layout/layout';

.image-container {
  max-width: 100%;
  overflow-y: auto;

  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  height:300px;
}

.scrollable-image {
width:100%;
}

.badge-status-success{
  color:#0C8E58;
  background-color:#D3F8E1;
}

.badge-status-warning-light{
  color:#FF9A01;
  background-color:#FEF0D8;
}

.badge-status-warning{
  color:#DF7514;
  background-color:#FFE0C9;
}

.badge-status-danger{
  color:#BD2624;
  background-color:#FDE2E2;
}

.dark-blue-font{
  color:#071437
}

a {
  color: $primary;
}

h1 {
  font-size: 20px !important;
  font-weight: 700 !important;
}

h2 {
  font-size: 18px !important;
  font-weight: 600 !important;
}

h3 {
  font-size: 13px !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-bottom: 0 !important;
}

.poppins {
  font-family: 'Poppins', sans-serif;
}

.vh-80 {
  height: 80vh !important;
}

.w-140px {
  width: 140px !important;
}

.w-260px {
  width: 260px !important;
}

.bg-gradiant-primary {
  background: radial-gradient(147.74% 247.06% at -75.53% -11.04%, #191ca6 0%, #2872cb 100%);
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Poppins', sans-serif;
}

input:focus {
  outline: none !important;
}

input:focus-visible {
  outline: none !important;
}

#dropzone {
  display: flex;
  justify-content: space-between;

  #template,
  #csvUpload {
    padding: 30px 3rem;
    border: 2px dashed #306df5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 49%;
    height: 150px;
    cursor: pointer;

    img {
      max-width: 100%;
    }
  }
}

.overflow-visible-important {
  overflow: visible !important;
}

.modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}

svg {
  max-width: 100%;
  vertical-align: middle;
}

.svg-pi {
  width: 100px;
  height: 100px;
  transform: rotate(-90deg);
}

.svg-pi-track {
  stroke: #d1d3de;
}

.svg-pi-indicator {
  stroke: #306df5;
  stroke-dasharray: 250.96px;
  /* DashArray */
  stroke-linecap: round;
}

.svg-pi-track,
.svg-pi-indicator {
  cx: 50px;
  cy: 50px;
  r: 40px;
  fill: transparent;
  stroke-width: 5px;
}

.svg-pi-25 .svg-pi-indicator {
  stroke-dashoffset: 250.96px;
  /* DashOffset = DashArray * ((100 - progress) / 100) */
}

.progress-cricle-bar {
  display: flex;
  position: relative;

  .progress-text {
    width: 100px;
    color: black;
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    bottom: 36px;
    left: 1px;
    text-align: center;
  }
}

.trailer-progress-bars {
  .progress-bar {
    border-radius: 6px;
  }

  .progress {
    background: none;
  }
}

#custom-accordion {
  .accordion-button {
    color: #306df5;
    padding-bottom: 6px;
    padding-top: 0;
  }

  .accordion-button:not(.collapsed) {
    background: none;
    box-shadow: none;
  }

  .accordion-button::after {
    margin: 0 auto;
    margin: 0 auto;
    font-family: 'keenicons-solid' !important;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    content: '\e9de';
    background-image: none;
    font-size: 24px;
    width: 24px;
    height: 28px;
  }
}

.card-header {
  .form-switch.form-check-solid .form-check-input:not(:checked) {
    background-color: #f1416c;
  }
}

.btn-blue-medium {
  &:hover {
    background-color: #306df5 !important;
  }
}

.btn-primary-reverse {
  color: $primary;
  border: none;
  background-color: white;
}

.notif-success {
  position: fixed;
  left: 10px;
  z-index: 10000;
  bottom: 1%;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0 4px;
  outline: none;
}

.capitalize::first-letter {
  text-transform: capitalize;
}

.lb-container {
  position: relative;
  display: inline-block;
}

.lb-image {
  max-width: 100%;
  height: auto;
  transition: all 0.3s ease;
}

.lb-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  border-radius: 0.475rem;
  transition: all 0.3s ease;
}

.lb-text {
  opacity: 1;
  color: white;
  text-align: center;
}

.lb-hovered .lb-image {
  filter: brightness(20%);
}

.lb-hovered .lb-overlay {
  opacity: 0.5;
  background-color: black;
}

.custom-box-shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.pink-progress {
  .progress-bar {
    background-color: #f991e1;
  }
}
.purple-progress {
  .progress-bar {
    background-color: #97027a;
  }
}

.badge-warning-light {
  background-color: #fff0d9;
  color: #ff9a02;
}

.bg-blur {
  backdrop-filter: blur(10px);
  max-width: 100%;
  overflow-x: scroll;

  &:hover {
    cursor: default;
  }
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn{
  color:white;
  background-color: #D1D3DE !important;
}
