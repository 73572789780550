//
// Required Label
//

.required {
  &:after {
    content: '*';
    position: relative;
    color: var(--#{$prefix}danger);
    padding-left: 0.25rem;
    font-weight: $font-weight-bold;
  }
}
